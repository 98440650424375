/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "The Martinique Lumière Yacht by Moonen"), "\n", React.createElement(_components.p, null, "Unfortunately, there is limited information available online about the Martinique Lumière yacht launched by Moonen. It appears that specific details about this particular yacht may not be easily accessible at the moment."), "\n", React.createElement(_components.p, null, "If you have any other inquiries or need assistance with something else, please feel free to reach out."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
